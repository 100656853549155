<template>
    <div class="col-5 col-lg-5 col-md-6 col-xs-12">
        <v-card
            elevation="2"
            outlined
            tile
        >
            <v-overlay
                :value="loader"
                absolute
                opacity="0.15"
            >
                <v-row>
                    <v-col class="text-center">
                        <v-progress-circular
                            color="primary"
                            indeterminate
                            size="40"
                            width="6"
                        />
                    </v-col>
                </v-row>
            </v-overlay>
            <v-card-title class="pt-2 pb-0">{{ $t('message.tmsDocuments') }}</v-card-title>
            <v-card-text class="text--black pb-1">
                <template v-for="(document,index) in TmsDocuments">
                    <div class="d-flex flex-row align-items-center">
                        <span style="color: black !important;">{{ document.Document.name }}</span>
                        <v-btn icon @click="viewFile(document.Document.location)">
                            <v-icon class="pl-2" style="color: darkgray !important;" small>fas fa-cloud-download-alt</v-icon>
                        </v-btn>
                    </div>
                </template>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
    import {api} from "Api";
    import {mapFields} from "vuex-map-fields";

    export default {
        name: "TmsContractDocumentsList",
        props: ['contractId'],
        data(){
            return {
                loader: false,
                TmsDocuments: []
            }
        },
        computed: {
            ...mapFields('runtime',{
                openedTabs: 'openedTabs'
            }),
        },
        methods: {
            loadTmsDocuments(contractId) {
                return new Promise((resolve, reject) => {
                    api
                        .get("/tms-documents/contract/" + contractId)
                        .then(response => {
                            resolve(response.data.data)
                        })
                        .catch(error => {
                            reject(error);
                        });
                })
            },
            viewFile(file) {
                let tab = window.open(file, '_blank')
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
            },
        },
        mounted() {
            if(this.contractId){
                this.loader = true
                this.loadTmsDocuments(this.contractId)
                    .then(response => {
                        this.TmsDocuments = response
                        this.loader = false
                    })
                    .catch(() => {
                        this.loader = false
                    })
            }
        },
    }
</script>

<style scoped>

</style>